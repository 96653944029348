<template>
<div>
  <v-card flat>
    <v-card-title>
      Organizations
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" dark class="mb-2" style="text-transform:none" @click="dialog=true">
        <v-icon left>mdi-plus</v-icon>
        Add new
      </v-btn>
    </v-card-title>
    
    <v-card-subtitle>Create organizations within your community (like guards, board, etc.). Your residents will be able to see these organizations thrught the mobile app.</v-card-subtitle>
    <v-card-text>
      <v-row class="mb-2">
        <v-col xs="12" sm="12" md="12" lg="6" xl="6" offset-lg="6" offset-xl="6">
          <v-text-field v-model="filtergroups" append-icon="mdi-magnify" label="Filter" clearable single-line hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-skeleton-loader type="list-item-two-line" :loading="!orgLoaded" transition="fade-transition">
        <v-data-table :headers="headers" :items="organizations" sort-by="name" :search="filtergroups" :items-per-page="25">
          <template v-slot:item.action="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list dense>
                  <v-list-item @click="editItem(item)" dense>
                    <v-list-item-content>
                      Edit
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>mdi-pencil</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="viewDetailInfo(item)" dense>
                    <v-list-item-content>
                      Configure Members
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>mdi-account-cog</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="orgToDelete(item)" dense>
                    <v-list-item-content>
                      Delete
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-action>
                  </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
  
  <v-dialog v-model="viewDialog" max-width="800">
    <v-card>
      <v-card-title>
        {{ selectedGroupName }} Members
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" dark class="mb-2" @click="openNewMemberDialog" style="text-transform:none">
          <v-icon left>mdi-plus</v-icon>
          Add new
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="px-6">Define members inside your organization</v-card-subtitle>
      <v-card-text>
        <v-skeleton-loader type="list-item-two-line" :loading="!memberLoaded" transition="fade-transition">
          <v-data-table :headers="detailMembersHeaders" :items="members" sort-by="name">
            <template v-slot:item.active="{ item }">
              {{ item.active ? 'Yes' : 'No' }}
            </template>
            <template v-slot:item.member_action="{ item }">
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list dense>
                    <v-list-item @click="editMember(item)" dense>
                      <v-list-item-content>
                        Edit
                      </v-list-item-content>
                      <v-list-item-action>
                          <v-icon>mdi-pencil</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="membertoDelete(item)" dense>
                      <v-list-item-content>
                        Delete
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-dialog>

  
  <v-dialog v-model="deleteMemberDialog" persistent max-width="400">
    <v-card>
      <v-card-title>Warning</v-card-title>
      <v-card-text>Are you sure you want to delete this member?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="deleteMemberDialog = false">Cancel</v-btn>
        <v-btn text @click="deleteMember" color="error">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="newMemberDialog" max-width="500" persistent>
    <v-card>
      <v-card-title>{{ editedMemberIndex == -1 ? 'Create' : 'Edit' }} Member</v-card-title>
      <v-card-subtitle class="px-6">Create or update member information</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="memberDetail.name" label="Member Name" counter="100" :error-messages="memberNameError"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="memberDetail.phone" label="Phone" v-mask="'(###) ###-####'"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="memberDetail.email" label="Email" counter="100"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="memberDetail.position" label="Position" counter="50" :error-messages="positionError" @keypress.enter="saveMember"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-switch v-model="memberDetail.active" label="Active"></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeMemberDialog">Cancel</v-btn>
        <v-btn color="primary" text @click="saveMember">{{ editedMemberIndex == -1 ? 'Save' : 'Update' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>{{ formTitle }}</v-card-title>
      <v-card-subtitle class="px-6">Create or edit an organization within your community</v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="editOrgItem.name" label="Name" counter="100" :error-messages="organizationNameError" @keypress.enter="saveOrg"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="editOrgItem.description" label="Description" counter="250"></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeOrgDialog">Cancel</v-btn>
        <v-btn color="primary" text @click="saveOrg">{{ editedIndex == -1 ? 'Save' : 'Update' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="deleteDialog" persistent max-width="400">
    <v-card>
      <v-card-title>Delete Organization</v-card-title>
      <v-card-text>Are you sure you want to delete this organization and all its members?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="deleteDialog = false">Cancel</v-btn>
        <v-btn text @click="deleteOrg" color="error">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { API } from '@/inc/api';
import { mask } from 'vue-the-mask';
import { _st } from '@/softech';

export default {
  data: () => ({
    dialog: false,
    headers: [
      { text: 'Name', value: 'name', align: 'left', sortable: false,  },
      { text: 'Description', value: 'description' },
      { text: 'Actions', align: 'right', value: 'action', sortable: false },
    ],
    detailMembersHeaders: [
      { text: 'Member Name', align:'left', value: 'name' },
      { text: 'Member Phone', value: 'phone' },
      { text: 'Member Email', value: 'email' },
      { text: 'Position', value: 'position' },
      { text: 'Active', value: 'active' },
      { text: 'Actions', value: 'member_action', align: 'right', sortable: false },
    ],
    filtergroups: '',
    orgLoaded: false,
    memberLoaded: false,
    memberEditLoaded: false,
    viewDialog: false,
    deleteDialog: false,
    newMemberDialog: false,
    deleteMemberDialog: false,
    itemToDelete: 0,
    itemMemberToDelete: 0,
    organizations: [],
    members:[],
    editedIndex: -1,
    editedMemberIndex: -1,
    memberDetail: {
      groupId: '',
      name: '',
      phone: '',
      email: '',
      avatarSrc: '',
      position: '',
      dateCreated: '',
      active: true,
      residentId: '',
      locationId: '',
    },
    memberNameError: '',
    positionError: '',
    organizationNameError: '',
    editOrgItem: {
      name: '',
      description: '',
    },
    defaultItem: {
      name: '',
      description: '',
    },
    selectedGroupId: 0,
    selectedGroupName: '',
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Organization' : 'Edit Organization'
    },
  },

  watch: {
    dialog (val) {
      val || this.closeOrgDialog()
    },
    newMemberDialog (val) {
      val || this.closeMemberDialog()
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let api = new API();

      try {
        this.orgLoaded = false;
        let res = await api.get('/admin/organizations');
        this.orgLoaded = true;

        if( !res.data.status ) {
          this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
          return;
        }
        
        this.organizations = res.data.data;
      }
      catch(error) {
        this.orgLoaded = true;
        this.mxShowMessage( error, 'error' );
      }
    },
    editItem(item) {
      // alert('Editar: ' + item.name);
      this.selectedGroupId = item.id;
      this.editedIndex = this.organizations.indexOf(item);
      this.editOrgItem = Object.assign({}, item);
      this.dialog = true;
    },
    editMember(item) {
      // alert('Editar: ' + item.name);
      this.editedMemberIndex = this.members.indexOf(item);
      this.memberDetail = Object.assign({}, item);
      this.newMemberDialog = true;
    },
    viewDetailInfo(item) {
      this.selectedGroupId = item.id;
      this.selectedGroupName = item.name;
      this.viewDialog = true;
      this.memberLoaded = true;

      this.members = item.members;
    },
    async saveOrganization() {
      this.organizationNameError = '';

      if( _st.isNUE( this.editOrgItem.name ) ) {
        this.organizationNameError = 'required';
        return;
      }

      let api = new API();

      try {
        this.orgLoaded = false;
        let res = await api.post('/admin/organization', {
          name        : this.editOrgItem.name,
          description : this.editOrgItem.description,
          id          : this.editOrgItem.id
        });

        this.orgLoaded = true;
        
        // If everything is ok, go to thankyou page
        if( !res.data.status && res.data.error ) {
            this.mxShowMessage( res.data.message, 'error' );
            return;
        }

        this.organizations.push( res.data.data );
        this.dialog = false;

        this.mxShowMessage( 'Organization created', 'success' );
      }
      catch(error) {
        console.log(error);
        this.mxShowMessage( error, 'error' );
      }
    },
    async updateOrganization() {
      this.organizationNameError = '';

      if( _st.isNUE( this.editOrgItem.name ) ) {
        this.organizationNameError = 'required';
        return;
      }

      let api = new API();

      try {
        let res = await api.put('/admin/organization', {
          name        : this.editOrgItem.name,
          description : this.editOrgItem.description,
          id          : this.editOrgItem.id
        });

        if( !res.data.status && res.data.error ) {
            this.mxShowMessage( res.data.message, 'error' );
            return;
        }
          
        let org = this.organizations.find(o => {
          return o.id == this.editOrgItem.id;
        });
        if( org == undefined && org == null )
          return;

        org.name = res.data.data.name;
        org.description = res.data.data.description;

        this.dialog = false;
        this.mxShowMessage( 'Organization updated', 'success' );
      }
      catch(error) {
        this.mxShowMessage( error, 'error' );
      }
    },
    async deleteOrg() {
      let api = new API() ;

      try {
        let res = await api.delete(`/admin/organization/${this.itemToDelete.id}`);

        if( !res.data.status && res.data.error ) {
            this.mxShowMessage( res.data.message, 'error' );
            return;
        }

        let ix = this.organizations.indexOf( this.itemToDelete );
        if( ix != undefined && ix != null )
          this.organizations.splice( ix, 1 );

        this.deleteDialog = false;
        this.mxShowMessage( 'Organization deleted', 'success' );
      }
      catch(error) {
        this.mxShowMessage( error, 'error' );
        this.deleteDialog = true;
      }
    },
    isMemberValid() {
      let errorFound = false;
      this.memberNameError = this.positionError = '';

      if( _st.isNUE( this.memberDetail.name == undefined ) || this.memberDetail.name.length < 3 ) {
        this.memberNameError = 'required';
        errorFound = true;
      }
      if( _st.isNUE( this.memberDetail.position == undefined ) ) {
        this.positionError = 'required';
        errorFound = true;
      }

      return !errorFound;
    },
    async newMember() {
      if( !this.isMemberValid() ) 
        return;

      let api = new API();

      try {
        this.memberLoaded = false;
        let res = await api.post('/admin/group/member', {
          groupId       : this.selectedGroupId,
          memberName    : this.memberDetail.name,
          memberPhone   : this.memberDetail.phone,
          memberEmail   : this.memberDetail.email,
          memberPic     : this.memberDetail.avatarSrc,
          position      : this.memberDetail.position,
          dateAdded     : this.memberDetail.dateCreated,
          active        : this.memberDetail.active,
          residentId    : null
        });

        this.memberLoaded = true;

        // If everything is ok, go to thankyou page
        if( !res.data.status && res.data.error ) {
            this.mxShowMessage( res.data.message, 'error' );
            return;
        }

        this.members.push( res.data.data );
        this.closeMemberDialog();

        this.mxShowMessage( 'Member created', 'success' );
      }
      catch(error) {
        this.mxShowMessage( error, 'error' );
        this.memberLoaded = true;
      }
    },
    async updateMember() {
      if( !this.isMemberValid() ) 
        return;

      let api = new API();

      try {
        this.memberEditLoaded = false;
        let res = await api.put('/admin/group/member', {
          id          : this.memberDetail.id,
          groupId     : this.memberDetail.groupId,
          memberName  : this.memberDetail.name,
          memberPhone : this.memberDetail.phone,
          memberEmail : this.memberDetail.email,
          memberPic   : this.memberDetail.avatarSrc,
          position    : this.memberDetail.position,
          dateAdded   : this.memberDetail.dateCreated,
          active      : this.memberDetail.active,
          residentId  : this.memberDetail.residentId
        });
        this.memberEditLoaded = true;

        if( !res.data.status && res.data.error ) {
          this.mxShowMessage( res.data.message, 'error' );
          return;
        }

        let member = this.members.find(m => {
          return m.id == this.memberDetail.id;
        });

        if( !_st.isNU( member ) ) {
          member.name = this.memberDetail.name;
          member.phone = this.memberDetail.phone;
          member.email = this.memberDetail.email;
          member.avatarSrc = this.memberDetail.avatarSrc;
          member.position = this.memberDetail.position;
          member.active = this.memberDetail.active;
          member.residentId = this.memberDetail.residentId;
        }

        this.closeMemberDialog();
        this.mxShowMessage( 'Member updated', 'success' );
      }
      catch(error) {
        this.mxShowMessage( error, 'error' );
        this.memberEditLoaded = true;
      }
    },
    orgToDelete (item) {
      this.deleteDialog = true;
      this.itemToDelete = item;
    },
    membertoDelete (item) {
      this.deleteMemberDialog = true;
      this.itemMemberToDelete = item;
    },
    async deleteMember () {
      let api = new API();

      try {
        this.memberEditLoaded = false;
        let res = await api.delete(`/admin/group/member/${this.itemMemberToDelete.id}`);
        this.memberEditLoaded = true;

        if( !res.data.status && res.data.error ) {
          this.mxShowMessage( res.data.message, 'error' );
          return;
        }

        let ix = this.members.indexOf(this.itemMemberToDelete);
        if( ix != undefined && ix != null ) {
          this.members.splice( ix, 1 );
        }
          
        this.deleteMemberDialog = false;
        this.mxShowMessage( 'Member deleted', 'success' );
      }
      catch(error) {
        this.memberEditLoaded = true;
        this.mxShowMessage( error, 'error' );
      }
    },
    closeOrgDialog () {
      this.dialog = false;
      setTimeout(() => {
        this.editOrgItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300)
    },

    closeMemberDialog() {
      this.newMemberDialog = false;
      this.editedMemberIndex = -1;
    },

    saveOrg () {
      if( this.editedIndex == -1 ) {
        this.saveOrganization();
      }
      else {
        this.updateOrganization();
      }
    },

    saveMember () {
      if (this.editedMemberIndex == -1) {
        this.newMember();
      }
      else {
        this.updateMember();
      }
    },
    openNewMemberDialog() {
      this.editedMemberIndex = -1;
      this.resetMemberDetailObj();
      this.newMemberDialog = true;
    },
    resetMemberDetailObj() {
      this.memberDetail = {
        groupId: '',
        name: '',
        phone: '',
        email: '',
        avatarSsrc: '',
        position: '',
        dateCreated: '',
        active: true,
        residentId: '',
        locationId: '',
      };
    }
  },
  directives: {
    mask,
  },
}
</script>